:root {
    color-scheme: dark;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #444;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

div {
    /*background-color: rgba(255, 255, 255, .1);*/
}
.app {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.app__top {}
.profile {
    position: absolute;
    top: 5px; right: 5px;
    z-index: 200;
    background-color: #191d21;
    padding: 5px 10px;
    font-size: 10px;
}

.app__content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    overflow-y: scroll;
}
.app__sidebar {
    /*overflow-y: scroll;*/
}
.app__map {
    flex: 1;
    position: relative;
}
.map__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* ------------------ ------------------ */
.fqbar {
    width: 150px;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.fqbar__bottom {
}
.fqbar__content {
    flex: 1;
    overflow-y: auto;
}
.fqbar__peleng-group {
    margin-bottom: 8px;
}
.fqbar__peleng {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.fqbar__peleng-time {

}
.fqbar__peleng-additional {

}
.fqbar__peleng-additional:hover {
    background-color: black;
    cursor: pointer;
}
/* ------------------ ------------------ */
.st-locations {}
.st-locations__loc {
    margin-bottom: 8px;
    position: relative;
    padding-left: 35px;
    padding-top: 14px;
    border-radius: 10px;
    padding-right: 10px;
}
.st-locations__loc:hover {
    background-color: #141e00;
}
.st-locations__radio {
    position: absolute;
    left: 0;
    top: 14px;
}
